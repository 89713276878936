@use '~@moda/om';

.ImageGallery {
  $self: &;

  position: relative;
  background-color: om.color(noise);
  overflow: hidden;

  &__primary-image {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__crossfader {
    display: none;
    position: absolute;
    inset: 0;
  }

  &--active {
    background-color: om.color('snow');

    // stylelint-disable-next-line plugin/selector-bem-pattern
    #{$self}__primary-image {
      opacity: 0;
    }

    // stylelint-disable-next-line plugin/selector-bem-pattern
    #{$self}__crossfader {
      display: block;
    }
  }

  &__carousel {
    height: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__carousel-image {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    scroll-snap-align: center;
  }
}
